import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export const shiftCalendarConfig = {
  initialDate: null,
  contentHeight: 'auto',
  timeZone: 'Asia/Taipei',
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
  buttonText: {
    today: '今日',
    month: '月檢視',
    week: '週檢視',
    day: '天檢視',
    list: '列表',
  },
  initialView: 'dayGridMonth',

  weekends: true,
  // longPressDelay: 100,
  selectLongPressDelay: 0,

  selectable: true,
  displayEventTime: false,
  displayEventEnd: true,
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  locale: 'en-gb',
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: false,
    hour12: false,
  },
  events: [],
  dayMaxEventRows: true, // for all non-TimeGrid views
  views: {
    timeGrid: {
      // dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
    },
    dayGrid: {
      dayMaxEventRows: 10, // adjust to 6 only for timeGridWeek/timeGridDay
    },
  },
}

export const editModeConfig = {
  preview: { label: '預覽', value: 'preview' },
  sketch: { label: '逐筆新增', value: 'sketch' },
  batch: { label: '批次新增', value: 'batch' },
}

export const pageTitleConfig = {
  preview: '編輯排班班表',
  batch: '批次新增',
  sketch: '逐筆新增',
}
