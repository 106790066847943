<template>
  <BaseDialog
    title="移除排班"
    width="610px"
    :style="warning ?`opacity: 0` : ''"
    :btn1-loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-form-item label="移除排班方式" prop="mode">
          <el-radio-group v-model="formData.mode">
            <el-radio label="single">僅移除這一筆</el-radio>
            <el-radio label="batch">移除此班別在指定日期區間的所有排班</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="formData.mode === 'batch'" label="選擇移除的指定日期區間" prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            style="width: 100%"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>
      </el-form>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { BatchDeleteUnitShiftSchedule, UpdateShiftsSchedule } from '@/api/shiftSchedule'
import { get, find } from 'lodash'
import dayjs from '@/lib/dayjs'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { useShiftCalendar } from '@/use/useShiftCalendar'

export default defineComponent({
  name: 'BatchCreateShiftScheduleModal',
  components: { BaseDialog },
  props: {
    shiftClassList: { type: Array, default: () => [] },
    unitSchedule: { type: Array, default: () => [] },
    shopBusinessTimeConfig: { type: Object, default: () => ({}) },
    context: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const warning = ref(false)
    const { initFormData, formRef, formData } = useBaseForm()
    const { updateShopShiftsSchedule } = useShiftCalendar()
    const { shopId } = useShop()

    initFormData({
      mode: 'single',
      dateRange: null,
    })

    const formRules = computed(() => {
      return {
        mode: [noEmptyRules()],
      }
    })

    const onConfirm = async () => {
      if (loading.value) return
      if (!await formUtils.checkForm(formRef.value)) return
      warning.value = false
      loading.value = true

      const event = get(props.context, 'selectEvent')
      if (formData.mode === 'single') {
        try {
          await UpdateShiftsSchedule({
            shopId: shopId.value,
            data: [{
              day: event.day,
              appointmentUnitId: event.appointmentUnitId,
            }],
          })
          loading.value = false
          window.$message.success(notifyMessage.updateSuccess)
          emit('refresh')
          emit('close')
          return
        } catch (error) {
          loading.value = false
          window.$message.error(error)
          return
        }
      }

      const start = get(formData.dateRange, '[0]')
      const end = get(formData.dateRange, '[1]')
      const [, err] = await BatchDeleteUnitShiftSchedule({
        shopId: shopId.value,
        appointmentUnitId: event.appointmentUnitId,
        appointmentScheduleDayId: event.appointmentScheduleDayId,
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
      loading.value = false
    }

    return {
      formRef,
      formData,
      formRules,
      onConfirm,
      loading,
      warning,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
