<template>
  <div class="edit-shift-calendar">
    <PageTitle
      :title="displayPageTItle"
      hideBtn
      :icon="editContext.mode === 'preview' || !useBatchCreateSchedule? 'chevron_left' : '' "
      @iconClick="$router.go(-1)"
    />

    <el-form label-position="top" :show-message="false">
      <FiltersContainer style="margin-bottom: 0">
        <div class="flex items-center">
          <el-form-item label="選擇服務人員">
            <ServicesUnitSelect data-cy="unit-select" :model.sync="editContext.serviceUnit" @change="changeServiceUnit" />
          </el-form-item>
        </div>
        <el-button v-if="editContext.mode === 'preview'" type="primary" class="primary-btn" style="width: 100px" @click="onCreate">新增排班</el-button>

        <el-form-item v-if="editContext.mode !== 'preview'" label="選擇排班班別">
          <el-select
            v-model="editContext.shiftClass"
            placeholder="請選擇"
            value-key="id"
            no-data-text="無數據"
            data-cy="shift-class-select"
          >
            <el-option
              v-for="item in shopShiftClassList"
              :key="item.name"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </FiltersContainer>
    </el-form>

    <div v-loading="loading" class="flex-1" style="margin-top: 22px">
      <FullCalendar v-if="show && shiftCalendar.initialDate" ref="calendarRef" class="h-full" :options="shiftCalendar" />
      <div style="height: 200px" />
    </div>

    <SelectEditShiftScheduleModeModal
      v-if="modal.selectEditMode"
      @close="modal.selectEditMode = false"
      @confirm="onEditModeChange"
    />

    <BatchCreateShiftScheduleModal
      v-if="modal.batchCreate"
      :shift-class-list="shopShiftClassList"
      :shopBusinessTimeConfig="shopBusinessTimeConfig"
      :context="editContext"
      @close="modal.batchCreate = false"
      @refresh="refresh"
    />
    <BatchDeleteShiftScheduleModal
      v-if="modal.batchDelete"
      :shift-class-list="shopShiftClassList"
      :shopBusinessTimeConfig="shopBusinessTimeConfig"
      :context="editContext"
      @close="modal.batchDelete = false"
      @refresh="refresh"
    />

    <PageFixedFooter
      v-if="editContext.mode === 'sketch'"
      :confirmBtn="useBatchCreateSchedule ? '儲存回到編輯排班' : '儲存'"
      @cancel="onCancelEdit"
      @confirm="saveEditSchedule"
    />

    <PageFixedFooter
      v-if="editContext.mode === 'batch'"
      hideCancel
      confirmBtn="回到編輯排班"
      @confirm="onCancelEdit"
    />
  </div>
</template>
<script>
import { get, filter, find, indexOf, cloneDeep } from 'lodash'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import FullCalendar from '@fullcalendar/vue'
import { FindServiceUnit } from '@/api/serviceUnit'
import { GetShopBusinessTimeConfig } from '@/api/shopBusinessTime'
import { useShop } from '@/use/shop'
import { useEditUnitSchedule, useShiftCalendar } from '@/use/useShiftCalendar'
import { useFullCalendar } from '@/use/useFullCalendar'
import { onMounted, reactive, ref, nextTick, watch, set, computed, onBeforeMount } from 'vue'
import SelectEditShiftScheduleModeModal from './components/SelectEditShiftScheduleModeModal.vue'
import BatchCreateShiftScheduleModal from './components/BatchCreateShiftScheduleModal.vue'
import BatchDeleteShiftScheduleModal from './components/BatchDeleteShiftScheduleModal.vue'
import { useFetch } from '@/use/fetch'
import { shiftCalendarConfig, pageTitleConfig, editModeConfig } from '@/config/shiftCalendar'
import { passValue } from '@/utils/helper'
import { useRoute } from 'vue-router/composables'
import dayjs from '@/lib/dayjs'
import { usePermissions } from '@/use/permissions'
import router from '@/router'

const loadDebounceKeyList = {}
function localDebounce (key, next) {
  const stl = 500
  if (loadDebounceKeyList[key]) {
    if (loadDebounceKeyList[key] + stl > +new Date()) {
      return () => {}
    }
  }
  loadDebounceKeyList[key] = +new Date()
  return next()
}

export default {
  name: 'ShifCalendarEdit',
  components: { ServicesUnitSelect, FullCalendar, BatchCreateShiftScheduleModal, SelectEditShiftScheduleModeModal, BatchDeleteShiftScheduleModal },
  // mixins: [shiftCalendar],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { simpleFetch, simpleFetchOld } = useFetch()
    const editContext = reactive({
      mode: 'preview',
      serviceUnit: null,
      shiftClass: null,
      selectDate: null,
      selectEvent: null,
    })
    const route = useRoute()
    const show = ref(true)
    const shopBusinessTimeConfig = ref({})
    const loading = ref(false)
    const calendarRef = ref(null)

    const search = reactive({
      serviceUnit: null,
    })
    const modal = reactive({
      selectEditMode: false,
      editSchedule: false,
      batchCreate: false,
      batchDelete: false,
    })
    const { checkAction } = usePermissions()
    const useBatchCreateSchedule = computed(() => checkAction('admin.appointmentSchedule.batchCreateByWeek'))
    const {
      calendarRange,
      calendarRangeString,
      getCalendarDate,
      createCalendarEvent,
    } = useFullCalendar()

    const {
      unitSchedule, shopSchedule,
      shopShiftClassList,
      getShopShiftsClass,
      getShopShiftsSchdule,
      updateUnitSchedule,
      updateShopShiftsSchedule,
    } = useShiftCalendar()

    const {
      cachedEditUnitSchedule,
      displayUnitSchedule,
      getUnitDisplaySchedule,
      updateUnitCachedEditSchedule,
      submitEditedSchedule,
    } = useEditUnitSchedule()

    const displayPageTItle = computed(() => {
      if (!useBatchCreateSchedule.value) return '編輯排班班表'
      return get(pageTitleConfig, editContext.mode)
    })

    // => 選擇服務人員 > 更新顯示人員班表
    const changeServiceUnit = async (v) => {
      loading.value = true
      updateUnitSchedule(v)
      getUnitDisplaySchedule({
        serviceUnitId: get(editContext.serviceUnit, 'id'),
        unitSchedule: unitSchedule.value,
      })
      refreshCalendarEvents({
        unitSchedule: displayUnitSchedule.value,
        shopShiftClassList: shopShiftClassList.value,
      })
      loading.value = false
    }

    // => 切換月曆時間
    const changeCalendarRange = async () => {
      const prevDayStart = get(calendarRangeString.value, 'dayStart')
      const { start, end } = getCalendarDate(calendarRef.value.getApi())
      calendarRange.start = start
      calendarRange.end = end
      const currentDayStart = get(calendarRangeString.value, 'dayStart')
      if (prevDayStart === currentDayStart) return
      await getShopShiftsSchdule({ dateRange: calendarRangeString.value })

      if (!editContext.serviceUnit) return

      updateUnitSchedule(editContext.serviceUnit)

      getUnitDisplaySchedule({
        serviceUnitId: get(editContext.serviceUnit, 'id'),
        unitSchedule: unitSchedule.value,
      })
      refreshCalendarEvents({
        unitSchedule: displayUnitSchedule.value,
        shopShiftClassList: shopShiftClassList.value,
      })
    }

    const onCreate = () => {
      if (!editContext.serviceUnit) {
        window.$message.warning('請先選擇欲排班之服務人員 !')
        return
      }
      modal.selectEditMode = true
    }

    const onEditModeChange = (mode) => {
      editContext.mode = mode
    }
    const clearEditCache = () => {
      cachedEditUnitSchedule.value = {}
    }
    const onCancelEdit = () => {
      if (!useBatchCreateSchedule.value) {
        router.push({
          name: 'ShiftsCalendarSetting',
        })
        return
      }
      editContext.mode = 'preview'
      clearEditCache()
      getUnitDisplaySchedule({
        serviceUnitId: get(editContext.serviceUnit, 'id'),
        unitSchedule: unitSchedule.value,
      })
      refreshCalendarEvents({
        unitSchedule: displayUnitSchedule.value,
        shopShiftClassList: shopShiftClassList.value,
      })
    }
    const refresh = async () => {
      loading.value = true
      clearEditCache()
      await getShopShiftsSchdule({ dateRange: calendarRangeString.value })
      await nextTick()
      await changeServiceUnit(editContext.serviceUnit)
      if (useBatchCreateSchedule.value && editContext.mode === 'sketch') {
        editContext.mode = 'preview'
      }
    }

    // 重建當前行事曆所有事件
    const refreshCalendarEvents = ({ unitSchedule, shopShiftClassList }) => {
      console.log('refreshCalendarEvents', shopShiftClassList)
      shiftCalendar.value.events = []
      for (const item of unitSchedule) {
        const shiftClass = find(shopShiftClassList, { id: item.appointmentScheduleDayId })
        const shiftClasstimesConfig = get(shiftClass, 'AppointmentScheduleDayTimes') || []
        for (const time of shiftClasstimesConfig) {
          const newEventData = createCalendarEvent({
            title: get(shiftClass, 'name'),
            day: item.day,
            timeStart: time.start,
            timeEnd: time.end,
            props: item,
            color: get(shiftClass, 'color'),
          })
          updateCalendarSignleDayEvent({
            mode: 'update',
            date: item.day,
            newEventData,
          })
        }
      }
    }

    // 更新單筆行事曆事件
    const updateCalendarSignleDayEvent = ({ mode, date, newEventData }) => {
      let events = cloneDeep(shiftCalendar.value.events)
      const isExist = find(events, { date })
      if (mode === 'remove') {
        events = filter(events, (item) => item.date !== date)
      } else {
        if (isExist) events.splice(indexOf(isExist, 1))
        events.push(newEventData)
      }
      shiftCalendar.value.events = events
    }

    // 點擊行事曆單天空白處
    const onDateClick = (date) => {
      date.jsEvent.preventDefault()
      if (editContext.mode === 'preview') return
      editContext.selectDate = date
      if (!editContext.shiftClass) {
        window.$message.warning('請先選擇班別')
        return
      } else if (!editContext.serviceUnit) {
        window.$message.warning('請先選擇服務人員')
        return
      } else if (editContext.mode === 'batch') {
        modal.batchCreate = true
        return
      }

      localDebounce(date.dateStr, () => {
        const day = date.dateStr
        const serviceUnitId = editContext.serviceUnit.id
        const shiftClassId = editContext.shiftClass.id

        const unitCachedEditSchedule = get(cachedEditUnitSchedule.value, serviceUnitId)

        const isExist = find(unitCachedEditSchedule, { day })

        if (!isExist) {
          // 當日尚無排班 -> 新增
          if (!get(cachedEditUnitSchedule.value, serviceUnitId)) set(cachedEditUnitSchedule.value, serviceUnitId, [])
          const newDataList = passValue(get(cachedEditUnitSchedule.value, serviceUnitId))
          newDataList.push({
            day,
            appointmentScheduleDayId: shiftClassId,
          })
          set(cachedEditUnitSchedule.value, serviceUnitId, newDataList)
        } else if (isExist.appointmentScheduleDayId !== shiftClassId) {
          // 當日已存在不同該班 -> 覆蓋
          set(cachedEditUnitSchedule.value[serviceUnitId], indexOf(cachedEditUnitSchedule.value[serviceUnitId], isExist), {
            day,
            appointmentScheduleDayId: shiftClassId,
          })
        }

        getUnitDisplaySchedule({
          serviceUnitId: serviceUnitId,
          unitSchedule: unitSchedule.value,
        })
        refreshCalendarEvents({
          unitSchedule: displayUnitSchedule.value,
          shopShiftClassList: shopShiftClassList.value,
        })
      })
    }
    // 點擊行事曆事件
    const onEventClick = (e) => {
      e.jsEvent.preventDefault()
      const eventProps = get(e, 'event.extendedProps')
      if (editContext.mode === 'preview' | editContext.mode === 'batch') {
        console.log('eventData', e.event)

        editContext.selectEvent = eventProps
        modal.batchDelete = true
        return
      }
      localDebounce(get(eventProps, 'day'), () => {
        const contextServiceUnitId = get(editContext.serviceUnit, 'id')
        const contextShiftClassId = get(editContext.shiftClass, 'id')
        const date = get(eventProps, 'day')
        console.group('onEventClick')
        console.log(date)
        console.log('eventData', e.event)
        const isEditDayExist = find(get(cachedEditUnitSchedule.value, contextServiceUnitId), { day: date })
        const isDayExist = find(get(unitSchedule.value, contextServiceUnitId), (i) => dayjs(i.day).format('YYYY-MM-DD') === date)
        console.log('isEditDayExist', isEditDayExist)
        console.log('isDayExist', isDayExist)

        if (isEditDayExist) {
          // 移除編輯班別緩存
          console.log('// 移除編輯班別緩存')
          updateUnitCachedEditSchedule({
            mode: 'remove',
            serviceUnitId: get(eventProps, 'appointmentUnitId'),
            shiftClassId: get(eventProps, 'appointmentScheduleDayId'),
            date,
          })
        }
        // 當天已有班別 -> 移除排班
        if (isDayExist) {
          // 標記移除班別
          console.log('// 標記移除班別')
          updateUnitCachedEditSchedule({
            mode: 'remove-update',
            serviceUnitId: get(eventProps, 'appointmentUnitId'),
            shiftClassId: get(eventProps, 'appointmentScheduleDayId'),
            date,
          })
        }

        getUnitDisplaySchedule({
          serviceUnitId: get(editContext.serviceUnit, 'id'),
          unitSchedule: unitSchedule.value,
        })
        refreshCalendarEvents({
          unitSchedule: displayUnitSchedule.value,
          shopShiftClassList: shopShiftClassList.value,
        })
        console.groupEnd()
      })
    }
    // 行事曆設定資料
    const shiftCalendar = ref({
      ...shiftCalendarConfig,
      customButtons: {
        prev: {
          click: () => {
            calendarRef.value.getApi().prev()
            changeCalendarRange()
          },
        },
        next: {
          click: () => {
            calendarRef.value.getApi().next()
            changeCalendarRange()
          },
        },
        today: {
          text: '今日',
          click: () => {
            calendarRef.value.getApi().today()
            changeCalendarRange()
          },
        },
      },
      dateClick: onDateClick,
      eventClick: onEventClick,
    })

    const findServiceUnit = async (id) => {
      if (!id) return
      await simpleFetchOld(FindServiceUnit, {
        shopId: shopId.value,
        id,
      }, (res) => {
        console.log(res)
        editContext.serviceUnit = res
      })
    }

    const saveEditSchedule = async () => {
      loading.value = true
      await updateShopShiftsSchedule(submitEditedSchedule.value)
      await refresh()
      loading.value = false
    }

    onBeforeMount(() => {
      if (!useBatchCreateSchedule.value) {
        editContext.mode = 'sketch'
      }
    })

    onMounted(async () => {
      const queryDate = get(route.query, 'date')
      const queryUnit = get(route.query, 'unit')
      if (queryDate) {
        shiftCalendar.value.initialDate = new Date(queryDate)
      } else shiftCalendar.value.initialDate = new Date()

      await Promise.all([
        getShopShiftsClass(),
        getShopShiftsSchdule({ dateRange: calendarRangeString.value }),
        simpleFetch(GetShopBusinessTimeConfig, { shopId: shopId.value }, (res) => {
          console.log('GetShopBusinessTimeConfig', res)
          shopBusinessTimeConfig.value = res
        }),
      ])

      if (queryUnit) {
        await findServiceUnit(queryUnit)
        await changeServiceUnit(editContext.serviceUnit)
      }
      // if (useBatchCreateSchedule.value) {
      //   editContext.mode = 'preview'
      // }
    })

    return {
      show,
      shiftCalendar,
      refresh,
      displayUnitSchedule,
      shopId,
      search,
      loading,
      calendarRange,
      calendarRangeString,
      getCalendarDate,
      unitSchedule,
      shopSchedule,
      shopShiftClassList,
      getShopShiftsSchdule,
      changeCalendarRange,
      changeServiceUnit,
      calendarRef,
      createCalendarEvent,
      updateShopShiftsSchedule,
      shopBusinessTimeConfig,
      modal,
      editContext,
      onCreate,
      onEditModeChange,
      onCancelEdit,
      cachedEditUnitSchedule,
      submitEditedSchedule,
      saveEditSchedule,
      displayPageTItle,
      useBatchCreateSchedule,
    }
  },
  computed: {
    sidebar () { return this.$store.state.app.sidebar },
  },

  watch: {
    sidebar () {
      // const api = this.$refs.calendarRef.getApi()
      // api.render()
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 300)
      console.log('resize')
    },
  },
}
</script>

<style scoped lang="postcss">

::v-deep .fc .fc-scroller-liquid-absolute {
  display: block;
  position:  unset !important;
  overflow: hidden !important;
  /* height: 100%; */
}

.edit-shift-calendar {
  @apply pb-[200px] mb-[200px] h-full flex flex-col;
}

.page-footer {

  box-shadow: 0px -4px 8px rgba(47, 32, 27, 0.04);
  padding: 15px 60px !important;
  /* background: white; */
  @apply fixed flex justify-end bottom-0 left-0 right-0 gap-[30px];
  @apply bg-white z-[5];
}

.controls-container {
  @apply flex gap-[24px] ;
}

@media screen and (max-width:1024px){
 .controls-container {
  @apply flex-wrap gap-0;
}
}
</style>
