<template>
  <BaseDialog append-to-body title="提醒" @confirm="$emit('confirm')" @close="$emit('close')">
    <div class="modal-body">
      <p>部份日期已有排班紀錄，點擊確定將會覆蓋現有排班班別，請問確定要覆蓋嗎？</p>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'ScheduleCollapseWarningModal',
  components: { BaseDialog },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.modal-body {
  @apply pb-[40px];
}
</style>
