<template>
  <BaseDialog
    title="批次新增班別"
    width="610px"
    :style="warning ?`opacity: 0` : ''"
    :btn1-loading="loading"
    @close="$emit('close')"
    @confirm="checkRangeOverride"
  >
    <div>
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-form-item label="勾選欲批次新增的星期別" prop="days">
          <el-checkbox-group v-model="formData.days">
            <div class="options-container">
              <el-checkbox v-for="day in weekdaysOption" :key="day.label" :label="day.week">
                {{ day.label }}<span v-if="day.isDisabled">（公休）</span>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="選擇欲批次新增的日期區間" prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            style="width: 100%"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="pickerOptions"
          />
        </el-form-item>
      </el-form>
    </div>

    <ScheduleCollapseWarningModal
      v-if="warning"
      @confirm="onConfirm"
      @close="(warning = false, loading = false);"
    />
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, ref, onBeforeMount } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import ScheduleCollapseWarningModal from './ScheduleCollapseWarningModal.vue'
import { useBaseForm } from '@/use/useForm'
import { GetServiceUnitRangeSchedule, BatchUpdateUnitShiftSchedule } from '@/api/shiftSchedule'
import { get, find } from 'lodash'
import dayjs from '@/lib/dayjs'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'BatchCreateShiftScheduleModal',
  components: { BaseDialog, ScheduleCollapseWarningModal },
  props: {
    shiftClassList: { type: Array, default: () => [] },
    unitSchedule: { type: Array, default: () => [] },
    shopBusinessTimeConfig: { type: Object, default: () => ({}) },
    context: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const warning = ref(false)
    const { initFormData, formRef, formData } = useBaseForm()
    const { shopId } = useShop()

    initFormData({
      days: [],
      dateRange: null,
    })

    const formRules = computed(() => {
      return {
        dateRange: [noEmptyRules()],
        days: [noEmptyRules()],
      }
    })

    const weekdaysOption = computed(() => {
      const weekdaysConfig = get(props.shopBusinessTimeConfig, 'customWeekConfigs') || []
      const weekDays = ['週日', '週一', '週二', '週三', '週四', '週五', '週六']
      const options = []

      // 如果 shopBusinessTimeConfig.mode === '24Hours'，回傳全部選項
      if (get(props.shopBusinessTimeConfig, 'mode') === '24Hours') {
        for (const idx in weekDays) {
          options.push({
            week: idx,
            label: weekDays[idx],
            isDisabled: false,
          })
        }
      }

      // 如果 shopBusinessTimeConfig.mode === 'CustomWeek'
      if (get(props.shopBusinessTimeConfig, 'mode') === 'CustomWeek') {
        for (const idx in weekDays) {
          const dayConfig = find(weekdaysConfig, { week: Number(idx) })
          options.push({
            week: idx,
            label: weekDays[idx],
            isDisabled: !dayConfig,
          })
        }
      }
      // 將第一筆(週日)移到最後
      options.push(options[0])
      options.shift()

      return options
    })

    const pickerOptions = {
      disabledDate: (date) => {
        // 可選 - 往後算三個月 (包含當月)
        const minMonth = dayjs(get(props.context, 'selectDate.date')).month()
        const maxMonth = dayjs(get(props.context, 'selectDate.date')).month() + 2
        const curMonth = dayjs(date).month()
        const fixedYear = dayjs(get(props.context, 'selectDate.date')).year()
        if (dayjs(date).year() < fixedYear || dayjs(date).year() > fixedYear) return date
        if (curMonth < minMonth) return date
        else if (curMonth > maxMonth) return date
      },
    }

    const checkRangeOverride = async () => {
      if (!await formUtils.checkForm(formRef.value)) return
      loading.value = true
      const start = get(formData.dateRange, '[0]')
      const end = get(formData.dateRange, '[1]')
      const [res, err] = await GetServiceUnitRangeSchedule({
        shopId: shopId.value,
        appointmentUnitId: get(props.context, 'serviceUnit.id'),
        weeks: formData.days,
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      if (get(res, 'hasAppointmentScheduleCount', '').toString() > 0) {
        warning.value = true
        return
      }
      onConfirm()
    }

    const onConfirm = async () => {
      if (loading.value) return
      warning.value = false
      loading.value = true
      const start = get(formData.dateRange, '[0]')
      const end = get(formData.dateRange, '[1]')
      const [, err] = await BatchUpdateUnitShiftSchedule({
        shopId: shopId.value,
        appointmentUnitId: get(props.context, 'serviceUnit.id'),
        appointmentScheduleDayId: get(props.context, 'shiftClass.id'),
        weeks: formData.days,
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
      loading.value = false
    }

    onBeforeMount(() => {
      const selectDate = get(props.context, 'selectDate')
      const selectDateWeekDay = dayjs(selectDate.dateStr).isoWeekday()
      formData.days.push(selectDateWeekDay === 7 ? '0' : selectDateWeekDay.toString())
    })

    return {
      formRef,
      formData,
      formRules,
      weekdaysOption,
      onConfirm,
      loading,
      pickerOptions,
      warning,
      checkRangeOverride,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
