<template>
  <BaseDialog title="新增排班" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" label-position="top">
      <el-form-item label="選擇排班新增方式" prop="mode">
        <el-radio-group v-model="formData.mode">
          <el-radio :label="editModeConfig.sketch.value">逐筆新增</el-radio>
          <el-radio :label="editModeConfig.batch.value">批次新增</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { editModeConfig } from '@/config/shiftCalendar'

export default defineComponent({
  name: 'SelectEditShiftScheduleModeModal',
  components: { BaseDialog },
  setup (props, { emit }) {
    const { initFormData, formRef, formData } = useBaseForm()
    initFormData({
      mode: editModeConfig.sketch.value,
    })
    const formRules = computed(() => {
      return {
        mode: [noEmptyRules()],
      }
    })
    const onConfirm = () => {
      emit('confirm', formData.mode)
      emit('close')
    }
    return { formRef, formData, formRules, onConfirm, editModeConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
